var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "Select a year",
      "options": _vm.filterYearOption,
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    on: {
      "input": _vm.onChangeFilterYear
    },
    model: {
      value: _vm.selectFilterYearValue,
      callback: function callback($$v) {
        _vm.selectFilterYearValue = $$v;
      },
      expression: "selectFilterYearValue"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    }
  }), _vm.$permissionAbility(_vm.LEAVE_TYPES_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create Leave Type ")])] : _vm._e()], 2)])])], 1), _c('div', [_c('vue-good-table', {
    attrs: {
      "line-numbers": false,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'format_name',
          type: 'asc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "style-class": "vgt-table striped"
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row2$employeeT, _props$row2$employeeT2, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7;
        return [props.column.field === 'eligible_employee_types' ? _c('div', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.employeeType ? [_c('b-badge', {
          staticClass: "mr-1"
        }, [_vm._v(" " + _vm._s((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$employeeT = _props$row2.employeeType) === null || _props$row2$employeeT === void 0 ? void 0 : (_props$row2$employeeT2 = _props$row2$employeeT.data) === null || _props$row2$employeeT2 === void 0 ? void 0 : _props$row2$employeeT2.name) + " ")])] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_name' ? _c('div', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s((_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.name) + " ")])]) : _vm._e(), props.column.field === 'format_leave_allowance' ? _c('div', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.leave_allowance) + " ")])], 1) : _vm._e(), [props.column.field === 'format_gender' ? _c('div', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": _vm.getGenderVariant((_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.gender)
          }
        }, [_vm._v(" " + _vm._s((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.gender) + " ")])], 1) : _vm._e()], props.column.field === 'format_leave_allow_before_days' ? _c('div', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v(" " + _vm._s((_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.leave_allow_before_days) + " ")])], 1) : _vm._e(), props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.LEAVE_TYPES_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.LEAVE_TYPES_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-leave-types-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Leave Type' : 'Create Leave Type',
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "leaveTypesValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Title",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Leave Type"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Leave Allowance",
      "label-for": "leave-allowance"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "leave allowance",
      "vid": "leave_allowance",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "leave-allowance",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "No. of Leaves"
          },
          model: {
            value: _vm.leaveAllowance,
            callback: function callback($$v) {
              _vm.leaveAllowance = $$v;
            },
            expression: "leaveAllowance"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Leave Apply Before Days",
      "label-for": "leave-allow-before-days"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "leave apply before days",
      "vid": "leave_allow_before_days",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "leave-allow-before-days",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "No. of Days"
          },
          model: {
            value: _vm.leaveAllowBeforeDays,
            callback: function callback($$v) {
              _vm.leaveAllowBeforeDays = $$v;
            },
            expression: "leaveAllowBeforeDays"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Gender",
      "label-for": "gender"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "gender",
      "vid": "gender",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "gender",
            "placeholder": "Select Gender",
            "options": _vm.genderOption,
            "reduce": function reduce(option) {
              return option.value;
            }
          },
          model: {
            value: _vm.selectGenderId,
            callback: function callback($$v) {
              _vm.selectGenderId = $$v;
            },
            expression: "selectGenderId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "sandwich"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    on: {
      "input": _vm.onChangeIsSandwichCheck
    },
    model: {
      value: _vm.isSandwichCheck,
      callback: function callback($$v) {
        _vm.isSandwichCheck = $$v;
      },
      expression: "isSandwichCheck"
    }
  }, [_vm._v(" Is Sandwich ")])], 1), _vm.isSandwichCheck ? [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Weekends",
      "label-for": "weekends"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    on: {
      "input": function input() {
        return _vm.weekendsReduceCustomDays = '';
      }
    },
    model: {
      value: _vm.isWeekendsAllDaysReduceCheck,
      callback: function callback($$v) {
        _vm.isWeekendsAllDaysReduceCheck = $$v;
      },
      expression: "isWeekendsAllDaysReduceCheck"
    }
  }, [_vm._v(" All Days Deduct "), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "custom-icon cursor-pointer",
    attrs: {
      "icon": "InfoIcon",
      "title": "Selecting 'All Days Deduct' will apply sandwich leave for weekends. For custom days, please enter the deduction number manually",
      "size": "15"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Custom Days Deduct",
      "label-for": "custom_days_reduce"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "custom_days_reduce",
      "vid": "weekends_reduce_custom_days"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "custom_days_reduce",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "ex: 1",
            "size": "sm",
            "disabled": _vm.isWeekendsAllDaysReduceCheck
          },
          model: {
            value: _vm.weekendsReduceCustomDays,
            callback: function callback($$v) {
              _vm.weekendsReduceCustomDays = $$v;
            },
            expression: "weekendsReduceCustomDays"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 369581150)
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Holidays",
      "label-for": "holidays"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    on: {
      "input": function input() {
        return _vm.holidaysReduceCustomDays = '';
      }
    },
    model: {
      value: _vm.isHolidaysAllDaysReduceCheck,
      callback: function callback($$v) {
        _vm.isHolidaysAllDaysReduceCheck = $$v;
      },
      expression: "isHolidaysAllDaysReduceCheck"
    }
  }, [_vm._v(" All Days Deduct "), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "custom-icon cursor-pointer",
    attrs: {
      "icon": "InfoIcon",
      "title": "Selecting 'All Days Deduct' will apply sandwich leave for holidays. For custom days, please enter the deduction number manually",
      "size": "15"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Custom Days Deduct",
      "label-for": "custom_days_reduce"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "custom_days_reduce",
      "vid": "weekends_reduce_custom_days"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "custom_days_reduce",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "ex: 1",
            "size": "sm",
            "disabled": _vm.isHolidaysAllDaysReduceCheck
          },
          model: {
            value: _vm.holidaysReduceCustomDays,
            callback: function callback($$v) {
              _vm.holidaysReduceCustomDays = $$v;
            },
            expression: "holidaysReduceCustomDays"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 67876254)
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Combined",
      "label-for": "combined"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    on: {
      "input": function input() {
        return _vm.combinedReduceCustomDays = '';
      }
    },
    model: {
      value: _vm.isCombinedAllDaysReduceCheck,
      callback: function callback($$v) {
        _vm.isCombinedAllDaysReduceCheck = $$v;
      },
      expression: "isCombinedAllDaysReduceCheck"
    }
  }, [_vm._v(" All Days Deduct "), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "custom-icon cursor-pointer",
    attrs: {
      "icon": "InfoIcon",
      "title": "Selecting 'All Days Deduct' will apply sandwich leave for both weekends and holidays. For custom days, please enter the deduction number manually",
      "size": "15"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Custom Days Deduct",
      "label-for": "custom_days_reduce"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "custom_days_reduce",
      "vid": "combined_reduce_custom_days"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "custom_days_reduce",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "ex: 1",
            "size": "sm",
            "disabled": _vm.isCombinedAllDaysReduceCheck
          },
          model: {
            value: _vm.combinedReduceCustomDays,
            callback: function callback($$v) {
              _vm.combinedReduceCustomDays = $$v;
            },
            expression: "combinedReduceCustomDays"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3420487006)
  })], 1)], 1)], 1)], 1)], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1)], 1), _vm.isLeaveTypeFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.modelType == "editModel" ? "Update" : "Submit") + " ")])]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }